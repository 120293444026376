/**
 * TODO: These need to be removed once all of the components have been moved to using MUI components
 * so that they don't need to rely on the old styling system.
 */

@import "../../../v1/styles/variables";

.component
{
    &.edit
    {
        .routes
        {
            padding-bottom: 0px;
            min-height: 100%;
        }
    }
}

.vendor-component-edit {
    .managed-by-vendor
    {  
        .editable-vendor-fields
        {
            input[name="cpnFreeForm"],
            input[name="itemNumber"],
            input[name="eid"],
            input[name="spec"],
            input[name="cpn"],
            select[name="unitOfMeasure"],
            select[name="spec"]
            {
                background-color: $BG_WHITE;
                pointer-events: all;
                font-size: 14px;
                border-color: transparent;
            }
        }
    }
}

.valispace-cmp-edit
{
   .managed-by-vendor
    { 
        input[name="mass"]
        {
            background-color: $BG_WHITE;
            pointer-events: all;
            font-size: 14px;
            border-color: transparent;
        }
    }
}

.valispace-cmp-edit, .vendor-component-edit 
{
    .managed-by-vendor
    {
         input, select[name="spec"]
        {
            background-color: #B3B3B3;
            color: #000000;
            font-size: 14px;
            pointer-events: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            border-color: transparent;
        }
        .table-icons-section
        {
            input
            {
                pointer-events: all;
            }
        }
        .ui-image-viewer
        {
            .drop-zone {
                opacity: 0.4;
                pointer-events: none;
            }
        }
        .documents-tab,.assembly-tab {
            .extended-table{
                .managed-by-vendor
                {
                    input
                    {
                        opacity: 0.8;
                    }
                }
            }
        }
        .top-block {
            .nav-block {
                padding: 7px 12px !important;
            }
        }
        .__react_component_tooltip.disable-input-tooltip {
            margin-left: 7px !important;
            margin-top: -3px !important;
            padding: 8px;
        }
        .remove-col{
            pointer-events: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            .ui-icon {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
}

.edit-component-route
{
    min-height: 100%;

    .open-right-nav {
        margin-right: 250px;
    }

    .banner-block
    {
        background : $CAUTION;

        > div
        {
            display         : flex;
            color           : black;
            justify-content : space-between;
            align-items     : center;

            > div
            {
                margin      : 0;
                display     : flex;
                align-items : center;
                margin      : 5px 0;
                height      : 40px;

                &:nth-child(1)
                {
                    .ui-icon
                    {
                        pointer-events : none;
                        width          : 16px;
                        height         : 19px;
                        margin-right   : 10px;

                        svg
                        {
                            fill : black;
                        }
                    }
                }

                &:nth-child(2)
                {
                    a
                    {
                        margin-right : 20px;
                        color        : black;
                    }

                    input[type="submit"]
                    {
                        padding      : 6px 10px;
                        color        : $FONT_YELLOW;
                        border-color : black;
                        background   : black;

                        &:hover
                        {
                            background : none;
                            color      : black;
                        }
                    }
                }
            }
        }
        .delete_btn {
            margin-right: 20px;
            color: black;
            background: transparent;
            border: 0;
            cursor: pointer;
        }
    }

    .description-box{
        padding-bottom: 30px;
        textarea{
            width: 446px;
            resize: none;
            height: 103px;
        }
    }

    .top-block
    {
        background: $BG_LIGHT;
        padding-bottom: 15px;
        overflow: auto;

        .vendor-label {
            display: inline-flex;
            margin: 15px 0 0;
        }

        input, select
        {
            font-size : $FONT_SIZE_DEFAULT;
            padding: 0px 6px;
            height: 24px;
        }
        select
        {
            font-size : $FONT_SIZE_12;
        }

        .category-drop-down
        {
            padding-right: 17px;
            background-position: right 5px center;
        }

        .nav-block
        {
            display         : flex;
            // justify-content : space-between;
            padding-top      : 15px;
            padding-bottom   : 10px;

            div
            {
                &:first-child
                {
                    color          : $GREY;
                    text-transform : uppercase;
                }

                &:last-child
                {
                    .ui-icon
                    {
                        pointer-events : none;
                        width          : 20px;
                        height         : 20px;

                        svg
                        {
                            fill : $CAUTION;
                        }
                    }
                }
            }

            .flex-v {
                display: flex;
                gap: 10px;
            }
        }

        .name-block
        {
            margin: 15px 0;
            padding-right: 20px;


            input
            {
                width: 500px;
                height: 30px;
                font-size: $FONT_SIZE_23;
                padding: 0px 10px;
            }
        }

        .info-block
        {
            display         : flex;

            table
            {
                width: auto;

                textarea
                {
                    height: 100px;
                }

                tr
                {
                    border-color: transparent;

                    th, td
                    {
                        padding: 0;
                        height: 30px;
                        font-weight: normal;
                        .custom-checkbox
                        {
                            input[name="revisionManaged"]
                            {
                                height: 16px;
                            }
                        }
                    }

                    th
                    {
                        width: 99px;
                    }

                    td
                    {
                        text-align: left;
                    }
                }
            }
            .addIn-only-fields {
                display: block;
                margin-top: 6px;
            }

            > div
            {
                margin-top: -5px;
                &:nth-child(1)
                {
                    margin-top: 0px;
                    flex-grow   : 0;
                    flex-shrink : 0;
                    flex-basis  : 175px;
                    margin-right: 30px;
                }

                &:nth-child(2)
                {
                    max-width: 310px;
                    min-width: 275px;
                    padding-right: 10px;

                    tr:nth-child(5) td
                    {
                        white-space: normal;
                        line-height: 20px;
                    }
                    table{
                        width: 100%;
                    }
                    th{
                        min-width: 127px;
                        max-width: 127px;
                        width: 127px;
                    }
                    td{
                        text-align: left;
                        padding: 0 10px;

                        input, select{
                            padding-bottom: 0px;
                        }
                        &.have-input{
                            padding-left: 10px;
                        }
                     }
                }

                &:nth-child(3)
                {
                    max-width: 280px;
                    min-width: 280px;
                    padding-right: 20px;
                    th
                    {
                        text-transform: uppercase;
                        max-width: 150px;
                        min-width: 150px;
                    }

                    td
                    {
                        max-width: 100px;
                    }
                }

                &:nth-child(4)
                {
                    flex-grow   : 0;
                    flex-shrink : 0;
                    flex-basis  : 190px;

                    max-width: 185px;
                    min-width: 185px;
                    padding-right: 20px;

                    th
                    {
                        min-width: 140px;
                    }
                    td
                    {
                        min-width: 110px;
                    }
                }
            }
            .default-img
            {
                background-image: linear-gradient(-180deg, #353540 0%, #18171D 100%);
                min-width: 185px;
                min-height: 144px;
                display: flex;
                align-items: center;
                justify-content: center;
                .ui-icon
                {
                    width: 68px;
                    height: 69px;
                    svg
                    {
                        width: 100%;
                        height: 100%;
                        fill: $GREY_LIGHT3;
                    }
                }
            }
            .rc-tooltip-placement-right
            {
                z-index: 9;
            }
        }
    }
    .description.app-row{
        background: $BG_LIGHT;
        .description-text{
            padding-bottom: 20px;
        }
    }

    .edit-cpn-field
    {
        display: flex;
        align-items: center;
        .edit-cpn-variant-field
        {
            display: flex;
            align-items: center;
            width: 58px;

            input
            {
                text-transform: uppercase;
            }
        }
    }
    .edit-cpn-variant-field
    {
        &.free-form-input {
            max-width: 100px;
            
            input[name="cpnVariant"]
            {
                background-color: $BG_WHITE;
                pointer-events: all;
                font-size: 14px;
                border-color: transparent;

                &.invalid
                {
                    background-color : $ERRORS;
                }
            }
        }

    }
    .primary-source
    {
        td, th
        {
            line-height: 17px;
            &:first-child
            {
                width: 110px;
                text-transform: uppercase;
            }

            &:nth-child(2)
            {
                width: 72px;;
            }
        }
        td.rolled-up-extended-cost-cell,
        td.rolled-up-cost-cell{

            .rolled-up-extended-cost.cmp-edit,
            .rolled-up-cost.cmp-edit
            {
                display: flex;
                align-items: center;
            }

            .rolled-up-extended-cost,
            .rolled-up-cost
            {
                background-color: #B3B3B3 !important;
                color: #000;
                padding-left: 4px;
                text-align: left;
                min-height: 24px;
                .extended-cost,
                .unit-price
                {
                    max-width: 79px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                }
            }
            &.valid{
                div
                {
                    height: 24px;
                    padding-top: 4px;
                }
            }
            span{
                &.not-all-valid{
                    display: flex;
                    align-items: center;
                    min-height: 24px;
                    padding-right: 6px;
                    .ui-icon{
                        height: 17px;
                        width: 17px;
                    }
                    svg{
                        width: 17px;
                        height: 17px !important;
                    }
                }
            }

        }
    }

    .mass-row
    {
        th
        {
            text-transform: none !important;
        }
        .rolled-up-mass-cell
        {
            display: flex;
            align-items: center;
            .not-all-valid
            {
                margin-left: 4px;
                z-index: 1;
                background: white;
                min-height: 24px;
                display: flex;
                align-items: center;
                padding-left: 4px;
            }
        }
    }
}

.confirm-status-changed-modal,
.new-warning-modal{
    .modal{
        width: 564px;
        padding: 28px 44px;

        h1, p{
            .ui-icon{
                width: 30px;
            }
            margin-bottom: 30px;
        }
        .inputs-section{
            div{
                padding-bottom: 10px;
                label, input, span{
                    display: inline-block;
                }
                input{
                    width: 80px;
                    height: 24px;
                }
                label{
                    width: 115px;
                }
            }
            .revision-comment
            {
                width: 328px;
                height: 40px;
            }
            .revision-alert-icon
            {
                margin-left: 10px;
            }
        }
        .bottom-section{
            width: 100%;
            margin-top: 25px;
            float: right;
            padding-top: 30px;
            border-top: 1px solid #979797;
            .button-sections{
                    float: right;
                button{
                    width: 138px;
                    height: 30px;
                    padding: 0;
                    margin-left: 10px;
                }
            }
        }
    }

    &.save-changes
    {
        .modal
        {
            width: 400px;
            h1
            {
                font-size: $FONT_SIZE_23;
            }
            .save-warning-text
            {
                margin-bottom: 0;
            }

            .bottom-section
            {
                margin-top: 30px;
                padding-top: 48px;
            }
        }

    }

}

.confirm-revision-changed-modal
{
    .modal{
        max-width: 564px;
        width: 100%;
        padding: 28px 44px;
        h1
        {
            margin-bottom: 24px;
        }
        p
        {
            margin-bottom: 20px;
        }
        .inputs-section{
            .revision-input-holder
            {
                padding-bottom: 8px;
            }
            div{
                label, input, span{
                    display: inline-block;
                }
                input{
                    width: 70px;
                    height: 25px;
                }
                label{
                    width: 115px;
                }
            }
            .revision-comment
            {
                width: 328px;
                height: 40px;
            }
        }
        .bottom-section{
            width: 100%;
            margin-top: 25px;
            float: right;
            padding-top: 30px;
            border-top: 1px solid #979797;
            .button-sections{
                    float: right;
                button{
                    width: 138px;
                    height: 30px;
                    padding: 0;
                    margin-left: 10px;
                }
            }
        }
    }
}

body[data-modal='open']
{
    .header
    {
        z-index: auto;
    }
    .ui-modal-box{
         z-index: 10000;
    }

    .component.edit,
    .product.edit
    {
        .__react_component_tooltip {
            padding: 5px 10px;
            max-width: 203px;
            text-align: left;
            font-style: normal;
        }

    }
}

.view-component-route.loading{
    background: red !important;
}

.vendor-component-view {
    .nav-block {
        ._crumb {
            padding: 7px 0px !important;
        }
        .vendor {
            padding: 7px 12px !important;
        }
        .vendor-label {
            .ui-icon {
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 0px;
            }
        }
    }
}

.view-component-route
{
    .Loader__foreground{
        background: $BG_LIGHT2;
        .Loader__message
        {
            @keyframes rotate
            {
                from {transform: rotate(0deg);}
                to {transform: rotate(360deg);}
            }

            width                     : 40px;
            height                    : 40px;
            animation-name            : rotate;
            animation-duration        : 0.4s;
            animation-iteration-count : infinite;
            animation-timing-function : linear;

            left: 50%;
            right: 50%;
            top: 300px;
            bottom: 50%;
            vertical-align: middle;
                display: block !important;

            .ui-icon
            {
                width  : 40px;
                height : 40px;

                svg
                {
                    fill: $GREEN;
                }
            }
        }
    }

    margin-bottom : -100px;

    .top-block
    {
        background: $BG_LIGHT;
        padding-bottom: 15px;

        .name-block
        {
            font-size: $FONT_SIZE_23;
            margin-bottom: 14px;
            line-height: 24px;
        }

        .info-block
        {
            display: flex;

            table
            {
                width: auto;

                tr
                {
                    border-color: transparent;

                    th, td
                    {
                        vertical-align: top;
                        padding-top: 0;
                        padding-bottom: 10px;
                        font-weight: 500;
                    }

                    th
                    {
                        font-weight: 500!important;
                        width: 105px;
                        overflow: auto;
                    }

                    td
                    {
                        text-align: left;
                    }
                }
            }

            > div
            {
                &:nth-child(1)
                {
                    flex-grow   : 0;
                    flex-shrink : 0;
                    flex-basis  : 175px;
                    margin-right: 30px;
                }

                &:nth-child(2)
                {
                    margin-right: 30px;
                    padding-right: 25px;

                    tr:nth-child(6) td
                    {
                        overflow: auto;
                        line-height: 20px;
                        &.description-text {
                            white-space: pre-line
                        }
                    }
                    table{
                        width: 304px;
                        th
                        {
                            width: 120px;
                            padding-right: 10px;
                        }
                        td
                        {
                            padding-right: 10px;
                        }
                    }
                }

                &:nth-child(3)
                {
                    padding-right: 25px;
                    width: 285px;

                    th
                    {
                        width: 165px;
                    }

                    td
                    {
                        width: 110px;
                    }
                }

                &:nth-child(4)
                {
                    flex-grow   : 0;
                    flex-shrink : 0;
                    flex-basis  : 190px;

                    th
                    {
                        width: 100px;
                    }
                }
            }
            td.specs-cell{
                width: 110px;
                max-width: 110px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .default-img
            {
                background-image: linear-gradient(-180deg, #353540 0%, #18171D 100%);
                min-width: 185px;
                min-height: 144px;
                display: flex;
                align-items: center;
                justify-content: center;
                .ui-icon
                {
                    width: 68px;
                    height: 69px;
                    svg
                    {
                        width: 100%;
                        height: 100%;
                        fill: $GREY_LIGHT3;
                    }
                }
            }
            .eid-info
            {
                a
                {
                    .co-link-icon
                    {
                        width: 15px;
                        height: 15px;
                        .open-link-icon
                        {
                            #arrow-area
                            {
                                stroke: #3CD1B5;
                            }
                        }
                    }
                    &:hover
                    {
                       .open-link-icon
                        {
                            #arrow-area
                            {
                                stroke: #FFF;
                            }
                        }
                    }
                }
            }
        }
    }

    .primary-source
    {
        td, th
        {
            line-height: 17px;
        }
        .mass-row
        {
            th
            {
                text-transform: none;
            }
        }
    }

    .specs
    {
        .mass-row
        {
            th
            {
                text-transform: none;
            }
        }
    }
}

.rolled-up-extended-cost-cell,
.rolled-up-cost-cell,
.rolled-up-mass-cell{
    .icon-cell
    {
        display: flex;
        align-items: center;
        gap: 6px;
        position: relative;
        min-height: 16px;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:not(.edit)
    {
        span{
            vertical-align: top;
        }
    }
    .not-all-valid{
        display: flex;
        .ui-icon{
            height: 17px;
            width: 17px;
        }
        svg{
            width: 17px;
            height: 17px !important;
        }
    }
}

.view-component-route .actions-block
{
    .banner-block
    {
        background : $MODIFED;
        &.archived
        {
            background : #F54A4F;
        }

        a
        {
            color           : black;
            margin          : 0px 5px;
            text-decoration : underline;

            &:hover
            {
                color: black;
            }
        }

        > div
        {
            display         : flex;
            color           : black;
            justify-content : space-between;
            align-items     : center;
            height          : 50px;

            > div
            {
                margin      : 0;
                display     : flex;
                align-items : center;
                margin      : 5px 0;

                &:nth-child(1)
                {
                    .ui-icon
                    {
                        pointer-events : none;
                        width          : 16px;
                        height         : 19px;
                        margin-right   : 10px;

                        svg
                        {
                            fill : black;
                        }
                    }
                }

                &:nth-child(2)
                {
                    a
                    {
                        margin-right : 20px;
                        color        : black;
                    }

                    input[type="submit"]
                    {
                        padding      : 5px 10px;
                        color        : $FONT_YELLOW;
                        border-color : black;
                        background   : black;

                        &:hover
                        {
                            background : none;
                            color      : black;
                        }
                    }
                }
            }
        }
    }

    .nav-block
    {
        display         : flex;
        justify-content : space-between;
        padding-top     : 19px;
        margin-bottom   : 5px;
        position        : relative;

        .header-left
        {
            width: 100%;
        }

        > div:last-child{
            height: 10px;
        }

        ._crumb{
            color: $GREY;
            text-transform: uppercase;
        }

        .vendor-label {
            position: absolute;
        }

        div
        {
            &:last-child
            {
                .ui-icon
                {
                    width       : 15px;
                    height      : 15px;
                    margin-left : 20px;
                    &.where-used-modal-spinner
                    {
                        width       : 0px;
                        height      : 0px;
                        margin-left : 0px;
                    }
                    &.active
                    {
                        svg
                        {
                            fill: $GREEN;
                        }
                    }

                    &.fav-icon
                    {
                        &:hover
                        {
                            svg
                            {
                                fill: white;
                            }
                        }

                        &.faved
                        {
                            svg
                            {
                                fill: #f55ee4;
                            }

                            &:hover
                            {
                                svg
                                {
                                    fill: #f55ee4;
                                }
                            }
                        }
                    }

                    &.variant-stroke
                    {
                        &:hover
                        {
                            svg
                            {
                                g
                                {
                                 stroke: $GREEN;
                                }

                            }
                        }
                    }
                }
            }
        }
    }

    .changeorder-actions-block{
        width: 15px;
        height: 15px;
        display: inline-block;
        margin-left: 0;
        position: inherit;
        .trigger{
            padding: 1px;
            position: inherit;
            &:hover{
                .ui-icon svg{
                    stroke: $GREEN;
                }
            }
        }
        .changeorder-actions{
            position: inherit;
            &.open{
                .sub-menu{
                    display: block;
                }
                .trigger{
                    .ui-icon svg g{
                        stroke: $GREEN;
                    }
                }
            }

            &.editable-while-in-CO
            {
                .sub-menu{
                    min-height: 76px;
                }
            }

            .sub-menu{
                z-index: 1;
                min-height: 105px;
                display: none;
                position: absolute;
                background: #17171F;
                margin-top: 5px;
                right: -20px;
                padding: 5px 0;
                text-align: left;
                white-space: nowrap;
                min-width: 219px;
                font-family: 'Roboto', sans-serif;
                div {
                    text-transform: none;
                }
                .option {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    margin:5px 5px 15px;
                    &:hover{

                        .ui-icon svg {
                            fill: $GREEN;
                        }
                        .ui-icon svg g path{
                            stroke: $GREEN;
                        }
                        .ui-icon svg g polygon{
                            fill: $GREEN;
                        }
                        .label {
                            color: $GREEN;
                        }
                    }
                    .ui-icon {
                        width: 16px;
                        height: 15px;
                        margin-right: 10px;
                        margin-top: -6px;
                    }
                    .label{
                        font-size: $FONT_SIZE_DEFAULT;
                        color: $FONT_COLOR;
                    }
                }
                .heading-holder {
                    font-size: $FONT_SIZE_DEFAULT;
                    margin:9px 15px;
                    text-transform: none;
                    color: $FONT_LIGHT;
                }
            }
        }
    }

    .flex-v {
        display: flex;
        gap: 10px;
    }
}
