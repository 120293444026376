@import "variables";
@import "duro-app-icon";

*, *:before, *:after
{
    box-sizing : inherit;
    margin     : 0;
    padding    : 0;
}

svg, button, a, input[type="submit"], th
{
    transition : $TRANSITION;
}
input,
select,
textarea:not(:where(.custom-text-area)){
    font-size: $FONT_SIZE_DEFAULT;
    &:active,
    &:focus
    {
        outline-color: $GREEN;
    }
}
div:not(:where(.MuiClock-root)),
.position-relative
{
    position : relative;
}

html
{
    min-height : 100%;
    position: relative !important;
    overflow-y: hidden;
}

.inlineBlock{
    display : inline-block;
}
.flex{
    display : flex;
}


body
{
    min-height: 100%;
    font-family : $FONT_FAMILY;
    background  : $BG_LIGHT;
    color       : $FONT_COLOR;
    font-size   : $FONT_SIZE_DEFAULT;
    min-width   : $MIN_WIDTH + ($SITE_MARGINS * 2);
    box-sizing  : border-box;

    input, select, textarea, button
    {
        font-family:inherit;
    }

    a
    {
        user-select     : none;
        text-decoration : none;
    }

    .grey{
        color: $GREY
    }

    .dark-grey{
        color: $GREY_LIGHT4
    }

    .light-grey{
        color: $GREY_LIGHT3
    }

    .green{
        color: $GREEN
    }

    h1
    {
        font-size   : $FONT_SIZE_23;
        font-weight : normal;
    }
    h2
    {
        font-size   : $FONT_SIZE_21;
        font-weight : normal;
    }
    h3
    {
        font-size   : $FONT_SIZE_DEFAULT;
        font-weight : normal;
    }

    a
    {
        font-size  : $FONT_SIZE_DEFAULT;
        color      : $GREEN;

        &:hover
        {
            color : $FONT_COLOR;
            cursor: pointer;
        }

        &.disabled{
            pointer-events: none;
        }
    }

    p, h3
    {
        line-height: 20px;
    }

    label:not(:where(.MuiFormControlLabel-root))
    {
        margin-bottom : 8px;
        display       : block;
    }

    input[type="tel"], input[type="text"]:not(.MuiInputBase-input), input[type="password"], textarea
    {
        width   : 100%;
        display : block;
        border  : 2px solid transparent;
        padding : 8px 10px;

        &::placeholder
        {
            color : $PLACEHOLDER;
        }

        &.invalid
        {
            border-color : $ERRORS;
            background : $ERRORS;
        }

        &.valid
        {
            border-color : $GREEN;
        }

        &:read-only > :not(:where(.Muidisabled))
        {
            background: $HIGHLIGHT;
            color: $FONT_COLOR;
        }
    }

    textarea
    {
        height: 150px;
    }

    input[type="submit"], a.btn, button:not(:where(.react-datepicker__navigation,
    .MuiButton-root,.MuiButtonBase-root, .Mui-selected, .PrivatePickersYear-yearButton))
    {
        border         : 1px solid $GREEN;
        background     : none;
        border-radius  : 5px;
        text-transform : uppercase;
        font-size      : 14px;
        padding        : 12px;
        color          : $GREEN;
        cursor         : pointer;
        min-width      : 100px;

        *
        {
            pointer-events: none;
        }

        svg
        {
            fill: $GREEN !important;
        }

        &:hover
        {
            background : $GREEN;
            color      : $FONT_LIGHT;

            svg
            {
                fill: $BG_LIGHT !important;
            }
        }

        &:focus
        {
            outline    : none;
            background : $GREEN;
            color      : $FONT_LIGHT;

            svg
            {
                fill: $BG_LIGHT !important;
            }
        }

        &.filled{
            background : $GREEN;
            color      : $FONT_LIGHT;
            svg
            {
                fill: $BG_LIGHT !important;
            }
            &:hover, &:focus{
                border         : 1px solid $GREEN;
                background     : none;
                color          : $GREEN;
            }
        }

        &.dark{
            background : transparent;
            color      : $GREY_LIGHT3;
            border         : 1px solid $GREY_LIGHT3;
            svg
            {
                fill: $GREY_LIGHT3 !important;
            }
            &:hover, &:focus{
                border         : 1px solid $GREY_LIGHT3;
                background     : $GREY_LIGHT3;
                color          : $BG_LIGHT3;
            }
        }

        &.danger
        {
            background : $ERROR;
            color      : $FONT_LIGHT;
            border     : 1px solid $ERROR;
            svg
            {
                fill: $BG_LIGHT !important;
            }
            &:hover, &:focus{
                background     : none;
                color          : $ERROR;
            }
        }

        &.disabled
        {
            pointer-events : none;
            cursor         : none;
            opacity        : 0.5;
            color          : $GREY;
            border-color   : $GREY;

            svg
            {
                fill: $GREY !important;
            }

            &:hover, &:focus
            {
                background : none;
                color      : $GREY;

                svg
                {
                    fill: $GREY !important;
                }
            }
        }

        &.cancel, &.empty
        {
            color        : $GREY;
            border-color : $GREY;

            svg
            {
                fill: $GREY !important;
            }

            &:hover
            {
                background   : none;
                color        : $GREEN;
                border-color : $GREEN;

                svg
                {
                    fill: white !important;
                }
            }

            &:focus
            {
                background : none;

                svg
                {
                    fill: white !important;
                }
            }
        }

        &.new-button
        {
            height: 27px;
            padding: 0;
            text-align: center;
            min-width: 75px;
            margin: 0 0 0 15px;

            &.ui-link.new-button{
                padding: 7px 11px 4px;
                border: 1px solid $GREEN;
                border-radius: 4px;
                margin: 0 0 0 15px;
                vertical-align: super;
                text-transform: uppercase;
                display: inline-block;
                line-height: 12px;
                min-width: 75px;
                text-align: center;
                &:hover {
                    background:$GREEN;
                }
            }
        }

        &.btn-black-theme
        {
            color: $FONT_YELLOW;
            background-color: #17171F;
            padding: 6px 20px;
            border-color: #17171F;
            min-width: auto;
            margin-left: 10px;
            &:hover,
            &.active,
            &:focus
            {
                background: transparent;
                color: #17171F;
                border-color: #17171F;
            }
        }
    }


    .ui-message, .help-text
    {
        height     : 25px;
        font-size  : 12px;
        font-style : italic;
        margin-top : 8px;

        &.valid
        {
            color : $GREEN;
        }

        &.invalid
        {
            color : $ERRORS;
        }
    }
    .help-text{
        font-style : normal;
    }

    .flex-h
    {
        display         : flex;
        justify-content : space-between;
    }

    .flex-v
    {
        display     : flex;
        align-items : center;
    }

    span.center-state.checkbox{
        display: inline-block;
        border: 1px solid $GREY_LIGHT3;
        width     : 15px;
        height    : 15px;
        text-align: center;
        cursor: default;

        &:after {
            content: "";
            color: $GREY_LIGHT3;
            position: absolute;
            height: -2px;
            height: 5px;
            width: 5px;
            border-radius: 50%;
            background: $GREY_LIGHT3;
            margin: 3.7px 0px 0px -2.5px;
        }

        &.white{
            border-color: $FONT_COLOR;
            &:after {
                color: $FONT_COLOR;
                background: $BG_WHITE;
            }
        }

        &.active,
        &:hover{
            border-color: $GREEN;
            &:after {
                color: $GREEN;
                background: $GREEN;
            }
        }

        &.disabled{
            cursor: default;
            border-color: $GREY_LIGHT3;
            &:after {
                color: $GREY_LIGHT3;
                background: $GREY_LIGHT3;
            }
        }

    }
    input[type="checkbox"]:not(:where(old, .PrivateSwitchBase-input))
    {
        width     : $CHECK_SIZE;
        height    : $CHECK_SIZE;
        font-size : 0;
        opacity   : 0;
        cursor    : pointer;

        & + label
        {
            display        : inline-block;
            width          : $CHECK_SIZE;
            height         : $CHECK_SIZE;
            border         : 1px solid $GREY;
            margin-left    : -$CHECK_SIZE;
            margin-bottom  : 0;
            pointer-events : none;
        }
        & + label.middle
        {
            position: relative;
            background: transparent !important;
            border: 1px solid $GREY !important;
            &:after
            {
                content: "_";
                width: 100%;
                margin: 0 auto;
                color: $GREEN;
                position: absolute;
                left: 20%;
                bottom: 2px;
            }
        }

        &:checked + label
        {
            background : $GREEN;
            border     : 1px solid $GREEN;
        }
    }

    select
    {
        width               : 100%;
        display             : block;
        border              : 1px solid transparent;
        padding             : 8px 10px;
        background-color    : white;
        background-image    : url("../assets/icons/arrow-select.svg");
        background-size     : 10px;
        background-position : right 8px center;
        background-repeat   : no-repeat;
        appearance          : none;
        box-shadow          : none;
        border-radius       : 0;
        height              : 31px;
        cursor              :  pointer;

        &.placeholder
        {
            color : $PLACEHOLDER;
        }

        option
        {
            color : #000000
        }

        &.valid
        {
            border-color: $GREEN;
        }

        &.invalid
        {
            border-color: $ERRORS;
        }
    }
    select,
    input[type="checkbox"],
    input[type="checkbox"] + label,
    input[type="text"]{
        &.disabled
        {
            background-color     : $HIGHLIGHT !important;
            color          : #8b7e7e !important;
            pointer-events : none;
            user-select    : none;
            border-color   : transparent !important;

            &.placeholder
            {
                color : $PLACEHOLDER;
            }
        }
        &.disabled.light
        {
          background-color: #B3B3B3 !important;
          color: $FONT_LIGHT !important;
        }
        &.disabled.dark
        {
          background-color: #B3B3B3 !important;
          color: $FONT_BLACK !important;
        }
        &.disabled.selected-from-library
        {
            background-color: #bfbfc6 !important;
        }

    }
    input[type="checkbox"].cross-checked{
        pointer-events: none;
    }
    input[type="checkbox"] + label{
        width: 12px;
        height: 12px;
        &.cross-checked {
            pointer-events: none;
            position: relative;
            overflow: hidden;
            &:before,
            &:after {
                background: $GREY;
                content: "";
                height: 1px;
                max-width: calc(15px * 1.414);
                position: absolute;
                // top: -1px;
                transition: 0.05s ease-in-out background-color;
                width: calc(2em * 1.414);
            }
            &:before{
                left: 0;
                -webkit-transform: rotate(44deg);
                -ms-transform: rotate(44deg);
                transform: rotate(44deg);
                -webkit-transform-origin: left;
                -ms-transform-origin: left;
                transform-origin: left;
            }
            &:after{
                right: 0;
                -webkit-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
                -webkit-transform-origin: right;
                -ms-transform-origin: right;
                transform-origin: right;
            }
        }
    }

    #root
    {
        > div {
            position: inherit;
        }
        height : 100vh;
        position: inherit;

        .app
        {
            height : 100vh;
            display    : flex;
            flex-flow  : column;

            .app-row
            {
                max-width     : $MAX_WIDTH + ($SITE_MARGINS * 2);
                padding-left  : 44px;
                padding-right : $SITE_MARGINS_OUTER;
                margin-left   : auto;
                margin-right  : auto;
            }

            .routes
            {
                flex : 1;
                min-height: 100%;
            }
        }
    }

    table
    {
        border-collapse : collapse;
        table-layout    : fixed;
        width           : 100%;
        text-align      : left;
        vertical-align  : top;

        tr
        {
            border-bottom : 1px solid $GREY;
            transition    : background 0.1s;

            th, td
            {
                overflow      : hidden;
                white-space   : nowrap;
                width         : 100%;
            }

            th
            {
                text-transform : uppercase;
                user-select : none;
                color       : $GREY;
                padding     : 0 20px 5px 0;
                font-weight : 700 !important;

                &.sortable
                {
                    cursor   : pointer;
                    position : relative;

                    > div,
                    > span.handler > div
                    {
                        pointer-events: none;
                        display:flex;

                        div:nth-child(1)
                        {
                            overflow         : hidden;
                            text-overflow    : ellipsis;
                            white-space      : nowrap;
                            margin-right     : 10px;
                        }

                        div:nth-child(2)
                        {
                            margin-top: 6px;
                            width: 10px;
                            height: 0;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-top: 6px solid $GREY;
                            cursor   : pointer;
                            opacity: 0.3;
                        }
                    }

                    &:hover
                    {
                        > div,
                        > span.handler > div div:nth-child(2)
                        {
                            opacity: 1;
                            border-top-color: $GREEN;
                        }
                    }

                    &.ascending
                    {
                        > div > div:nth-child(2),
                        > span.handler > div div:nth-child(2)
                        {
                            width: 10px;
                            height: 0;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-top: 6px solid $GREEN;
                            opacity: 1 !important;
                        }
                    }

                    &.descending
                    {
                        > div > div:nth-child(2),
                        > span.handler > div div:nth-child(2)
                        {
                            opacity: 1 !important;
                            width: 10px;
                            height: 0;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-bottom: 6px solid $GREEN;
                            border-top: none;
                        }
                    }
                }

                &:last-child
                {
                    padding-right : 0;
                    margin-right  : 0;
                    text-align    : right;
                }
            }

            td
            {
                padding : 9px 20px 9px 0;
                &:last-child
                {
                    padding-right : 0;
                    text-align    : right;
                }
            }

            &.expandable
            {
                display : none;

                > td
                {
                    margin     : 0;
                    padding    : 0;
                    text-align : left;
                }

                &.expanded
                {
                    display : table-row;
                }
            }
        }
    }

    .ui-image-viewer
    {
        .image-copy
        {
            display:none;
        }
    }
    .__react_component_tooltip
    {
        border-radius   : 0 !important;
        max-width       : 250px;
        width           : auto;
        // min-width       : 80px;
        opacity         : 1 !important;
        text-align      : center;
        padding         : 5px;
        font-size       : $FONT_SIZE_DEFAULT;
        white-space     : normal;
        z-index: 99999 !important;
        user-select     : none !important;
        word-break      : break-word;
        &.full-width{
            max-width       : 100%;
        }

        &.type-error
        {
            background-color: $ERROR !important;

            &.place-right:after
            {
                border-right-color: $ERROR !important;
            }

            &.place-top:after
            {
                border-top-color: $ERROR !important;
            }
        }

        &.place-right
        {
            margin-left: 15px !important;
        }
    }
    .rc-tooltip{
            opacity: 1 !important;
            font-size: $FONT_SIZE_DEFAULT;
            &.hidden{
                display: none;
            }
            &.error {
                .rc-tooltip-inner{
                    background-color: $ERROR !important;
                    border: 1px solid $ERROR !important;
                    color: $FONT_COLOR;
                    font-size: 14px;
                    border-radius: 0px !important;
                }
                &.rc-tooltip-placement-top,
                &.rc-tooltip-placement-topRight{
                    .rc-tooltip-arrow {
                        border-top-color: $ERROR !important;
                        .rc-tooltip-arrow-inner{
                            border-top-color: $ERROR !important;
                        }
                    }
                }
                &.rc-tooltip-placement-right{
                    .rc-tooltip-arrow {
                        border-right-color: $ERROR !important;
                        .rc-tooltip-arrow-inner{
                            border-right-color: $ERROR !important;
                        }
                    }
                }

                &.rc-tooltip-placement-left{
                    .rc-tooltip-arrow {
                        border-left-color: $ERROR !important;
                        .rc-tooltip-arrow-inner{
                            border-left-color: $ERROR !important;
                        }
                    }
                }
            }
            .rc-tooltip-content{
                opacity: 1;
                .rc-tooltip-inner{
                    opacity: 1 !important;
                }
            }

        &.custom-elipses-tip {
            .rc-tooltip-inner{
                background-color: #000 !important;
                border: 1px solid #000 !important;
                color: $FONT_COLOR!important;
                padding: 2px 10px;
                min-height: auto;
                max-width: 450px;
                text-align: center;
                word-break: break-word;
            }
            .rc-tooltip-arrow {
                border-top-color: #000 !important;
            }
        }
        &.no-primary-source-tooltip
        {
            &.rc-tooltip-placement-top
            {
                .rc-tooltip-content
                {
                    max-width: 225px;
                    .rc-tooltip-arrow
                    {
                        border-top-color: black!important;
                    }
                    .rc-tooltip-inner
                    {
                        background-color: black!important;
                        border: 1px solid black !important;
                        padding-left: 10px!important;
                        padding-right: 10px!important;

                    }
                }
            }
        }
        &.cmp-title
        {
            .rc-tooltip-content
            {
                .rc-tooltip-arrow
                {
                    border-top-color: black!important;
                }
                .rc-tooltip-inner{
                    background-color: #000 !important;
                    border: 1px solid #000 !important;
                    color: $FONT_COLOR!important;
                    text-align: center;
                    word-break: break-word;
                }
            }
        }
        &.tooltip-no-width
        {
            .rc-tooltip-content
            {
                .rc-tooltip-inner
                {
                    max-width: unset;
                }
            }
        }
    }
    .with-existing-btn{
        max-width: 300px;
        width: 100%;
        opacity: 1 !important;
        &.hidden{
            display: none;
        }
        &.error {
            .rc-tooltip-inner{
                background-color: $BLACK_ERROR !important;
                border: 1px solid $BLACK_ERROR !important;
                color: $FONT_COLOR;
                font-size: 14px;
                border-radius: 0px !important;
                padding: 20px;
            }
            &.rc-tooltip-placement-top,
            &.rc-tooltip-placement-topRight{
                .rc-tooltip-arrow {
                    border-top-color: $BLACK_ERROR !important;
                    .rc-tooltip-arrow-inner{
                        border-top-color: $BLACK_ERROR !important;
                    }
                }
            }
            &.rc-tooltip-placement-right{
                .rc-tooltip-arrow {
                    border-right-color: $BLACK_ERROR !important;
                    .rc-tooltip-arrow-inner{
                        border-right-color: $BLACK_ERROR !important;
                    }
                }
            }

            &.rc-tooltip-placement-left{
                .rc-tooltip-arrow {
                    border-left-color: $BLACK_ERROR !important;
                    .rc-tooltip-arrow-inner{
                        border-left-color: $BLACK_ERROR !important;
                    }
                }
            }
        }
        .rc-tooltip-content{
            opacity: 1;
            .rc-tooltip-inner{
                opacity: 1 !important;
                .error-message
                {
                    display: block;
                    margin-bottom: 5px;
                }
                .ui-link{
                    .link-text{
                        color: $GREEN !important;
                        padding-right: 17px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 100%;
                        display: inline-block;
                        position: relative;
                    }
                    g{
                        fill: $GREEN;
                    }
                }
            }
        }

        &.custom-elipses-tip {
            .rc-tooltip-inner{
                background-color: $BLACK_ERROR !important;
                border: 1px solid $BLACK_ERROR !important;
                color: $FONT_COLOR!important;
                padding: 2px 10px;
                min-height: auto;
                max-width: 450px;
                text-align: center;
                word-break: break-word;
            }
            .rc-tooltip-arrow {
                border-top-color: $BLACK_ERROR !important;
            }
        }
    }
    .rc-tooltip:not(.default-styles) {

        opacity: 9;
        background-color: transparent;
        &.hidden{
          display: none;
        }
        &.error {
            .rc-tooltip-inner{
                background-color: $ERROR !important;
                border: 1px solid $ERROR !important;
            }
            .rc-tooltip-arrow {
                border-right-color: $ERROR !important;
            }
        }

        &.on-boarding-tool-tip,
        &.blue-rc-tool-tip
        {
            .rc-tooltip-inner{
                background-color: $BLUE_TOOL_TIP_COLOR !important;
                border: 1px solid $BLUE_TOOL_TIP_COLOR !important;
            }
            .rc-tooltip-arrow {
                border-right-color: $BLUE_TOOL_TIP_COLOR !important;
            }
            .rc-tooltip-inner {
                box-shadow: none !important;
                font-size: $FONT_SIZE_19;
                font-style: normal;
                font-weight: 600;
                font-family: 'Roboto', sans-serif;
                border-radius: 0;
                padding: 10px 18px;
                line-height: 23px;
                min-height: auto;
                span{
                    color: $FONT_COLOR;
                    font-size: $FONT_SIZE_DEFAULT;
                }
            }

        }

        &.rc-tooltip-placement-right {
            .rc-tooltip-content{
                margin-left: 5px !important;
            }
        }
        .rc-tooltip-arrow{
            left: -6px;
        }
        .rc-tooltip-inner {
            font-style: italic;
            font-family: 'Roboto', sans-serif;
            border-radius: 0;
            padding: 4px 5px;
            line-height: 15px;
            min-height: auto;
            span{
                color: $FONT_COLOR;
                font-size: $FONT_SIZE_DEFAULT;
            }
        }
        &.right-arrow-tooltip{
            .rc-tooltip-arrow {
                z-index: 9;
                right: -6px;
                left: auto;
                border-left-color: $BLUE_TOOL_TIP_COLOR !important;

            }
        }
    }
    .rc-tooltip.on-boarding-tool-tip.vendor-tooltip.rc-tooltip-placement-right
    {
        left: 607px !important;
        top: 28px !important;
    }
    .update-status-screen-tool-tip
    {
        line-height: 1.25!important;
        max-width: 230px;
        .rc-tooltip-inner{
            padding-right:10px!important;
            .ta-right{
                text-align: right;
                .open-btn{
                    min-width: 60px;
                    margin-right: 0px;
                    padding: 4px 10px ;
                    background-color: black;
                    border-color: black;
                    color: $FONT_COLOR;
                }
            }
            .co-text{
                color: #F8CFD0;
                svg{
                    g{
                        fill: #F8CFD0;
                    }
                }
            }
        }
    }
    .custom-mx-width-for-bulk-status-update
    {
        max-width: 208px;
        .rc-tooltip-inner{
            padding-left: 10px!important;
        }
    }
    .bulk-update-heading-info-tooltip
    {
        &.error
        {
            .rc-tooltip-content
            {
                .rc-tooltip-inner
                {
                    background: #04ADC3 !important;
                    border: 1px solid #04ADC3 !important;
                }
                .rc-tooltip-arrow
                {
                    border-top-color: #04ADC3 !important;
                }
            }
        }
    }
}

// scroll with a hidden scrollbar
// https://stackoverflow.com/a/49278385/15937399
div.ps {
    overflow: hidden !important;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

div.ps > div.ps__rail-y,
div.ps > div.ps__rail-x {
    overflow: auto !important;
}

.ps__rail-x.ps--clicking,
.ps__rail-y.ps--clicking {
    background-color: transparent !important;
}

div.ps::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.stop-scrolling
{
  height: 100%;
  overflow: hidden;
}
.pr-25
{
    padding-right: 25px !important;
}

.modified{
    svg {
        width: 6px;
        height: 6px;
        vertical-align: top;
        margin-left: 1px;
        g{
            stroke: none;
        }
    }
}

.muted-text {
  color: $GREY_LIGHT3;
}

@keyframes modalFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.zoomInAnimation {
  animation-name: modalFade;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

@keyframes modalFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.zoomOutAnimation {
  animation-name: modalFadeOut;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.text-center{
    text-align: center;
}

.DESIGN.font{
    color: #9ACCFF;
}
// svg css start

.sub-menu
{
    .option{
        &:hover{
        #width-auto-adjust-svg,
        #collapse-icon,
        #grid-svg-icon
        {
                g
                {
                    g
                    {
                        fill: $GREEN;
                        stroke: $GREEN;
                    }
                }

            }
        }
    }
}

#collapse-icon{
    .active,
    &:hover{
        g{
            fill: $GREEN;
        }
        #auto-icon{
            fill: $GREEN;
            stroke: $GREEN;
        }
    }
}
.table-settings-wrapper{
    &:hover{
        svg{
            g{
                fill: #3CD1BF;
                stroke: #3CD1BF;
            }
            #Download
            {
                #Export
                {
                    fill: none;
                }
            }
        }
    }
}


a.open-link-holder{
    &.duplicate-sourcing-warning {
        margin: 0px !important;
        padding: 0px !important;
        border-top: 0px !important;
    }
    .ui-icon{
        height: 100%;
    }
    &.green{
        color: $GREEN !important;
    }

    .open-link-icon{
        g{
            fill: $GREEN;
        }
    }
    &.white{
        g{
            fill: #F8CFD0;
        }
        color: white !important;
        .link-text{
            color: #F8CFD0 !important;
        }
    }
}

.with-existing-btn{
    a.open-link-holder{
        .ui-icon{
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            width: 18px;
        }
        &.green{
            color: $GREEN !important;
        }

        .open-link-icon{
            g{
                fill: $GREEN;
            }
        }
        &.white{
            g{
                fill: #F8CFD0;
            }
            color: white !important;
            .link-text{
                color: #F8CFD0 !important;
            }
        }
    }
}

.new-file-rc-tooltip{
    .error-message{
        display: block !important;
    }
    a.open-link-holder{
        .link-text{
            color: #F8CFD0 !important;
        }
        .ui-icon {
            position: inherit !important;
            g{
                fill: #F8CFD0;
            }
        }
    }
}
// check-box-for-primary-source-selection
.ui-icon{
    &.primarySource
    {
        #checkbox-svg-with-dot{
            g{
                fill: none;
            }

            #Primary{
                stroke: $FONT_COLOR;
            }
            circle{
                stroke: $FONT_COLOR;
                fill: $FONT_COLOR;
            }
        }
    }
}

// svg css end
.radio{
    input {
        width: 11px;
        height: 11px;
        font-size: 0;
        opacity: 0;
        cursor: pointer;
    }
    label {
        display: inline-block;
        width: 11px;
        height: 11px;
        border: 1px solid #b3b3b3;
        margin-left: -11px;
        margin-bottom: 0;
        pointer-events: none;
        border-radius: 100%;
        margin-right: 10px;
    }
    input[type="radio"]:checked+label
    {
        background: $GREEN;
        border: 1px solid $GREEN;
    }

    &.large{
        input {
            width: 15px;
            height: 15px;
        }
        label {
            width: 15px;
            height: 15px;
            margin-left: -15px;
        }
    }
}

.visibilityHidden{
    visibility: hidden;
}

.status-label{
    padding: 0px 10px;
    color: #18171D;
    font-size: $FONT_SIZE_DEFAULT;
    height: 20px;
    line-height: 0px;
    border-radius: 10rem;
    &.DESIGN{
        background-color: #9ACCFF;
    }
    &.PRODUCTION{
        background-color: #7AE173;
    }
    &.PROTOTYPE{
        background-color: #FFFD91;
    }
    &.OBSOLETE{
        background-color: $FONT_LIGHT;
    }

    &.OBSOLETE{
        background-color: $FONT_LIGHT;
    }

    &.REVIEW{
        border: 1px solid #9ACCFF;;
        background-color: unset;
        color: #9ACCFF;
    }
    &.OPEN{
        border: 1px solid #9ACCFF;;
        background-color: unset;
        color: #9ACCFF;
    }
    &.CLOSED{
        border: 1px solid $GREY_LIGHT3;;
        background-color: unset;
        color: $FONT_LIGHT;
    }
    &.DRAFT{
        border: 1px solid #FFFD91;
        background-color: unset;
        color: #FFFD91;
    }
    &.APPROVED{
        border: 1px solid #7AE173;
        color: #7AE173;
    }
    &.REJECTED{
        border: 1px solid #F54A4F;
        color: #F54A4F;
    }
}

.vendor-label{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px 10px;
    color: #18171D;
    font-size: $FONT_SIZE_DEFAULT;
    height: 20px;
    line-height: 0px;
    border-radius: 10rem;
    &.DESIGN{
        background-color: #04ADC3;
    }
    .ui-icon {
        pointer-events: none;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    >span{
        color: $FONT_COLOR;
        margin-left: 5px;
    }
}

.resolution-label{
    color: $FONT_LIGHT;
    &.PENDING{
        color: #FFFD91;
    }
    &.APPROVED{
        color: #7AE173;
    }
    &.REJECTED{
        color: $ERROR;
    }
    &.EDITED{
        color: #9ACCFF;
    }
    &.CREATED{
        color: #FFFD91;
    }

    &.SUBMITTED{
        color: #F4A543;
    }

    &.VIEWED{
        color: #B383DE;
    }
    &.FINALIZING{
        color: #F4A543;
    }

}

.user-avatar{
    .user-img{

        display: inline-block;
        width: 30px;
        height: 30px;
        margin-right: 5px;
        img{

            width: 30px;
            height: 30px;
            border-radius: 100%;
        }
    }
}
.default-avatar
{
    height: 100%;
    padding-top: 9px;
    .default-icon{
        border-radius: 100%;
        border: 1px solid white;
        width: 30px;
        height: 30px;
        margin-right: 5px;

        padding: 6px;
        .ui-icon {
            width: 15px;
            height: 15px;
            top: 2px;
        }
    }
}
.white-font{
    color: $FONT_COLOR;
}
.mt10{
    margin-top: 10px;
}

.mb10{
    margin-bottom: 10px;
}

.mr15{
    margin-right: 15px;
}

.default-cursor{
    cursor: default !important;
}

.link
{
    color: $GREEN;
    cursor: pointer;
    &:hover {
        color: $FONT_COLOR;
    }
}

.modified-alert-icon{
    margin-left: 1px;
    width: 0;
    height: 0;
    display: inline-block;
    vertical-align: top;
    border-style: solid;
    border-width: 0 3px 6px 3px;
    border-color: transparent transparent #f54a50 transparent;
}

.open-link-icon{
    margin-left: 5px;
}

body > object
{
    &.object
    {
        position: absolute;
    }
}
.simple-rc-tip{
    .rc-tooltip-inner
    {
        padding-left: 15px !important;
        padding-right: 15px !important;
        font-style: normal !important;
    }
    &.error{
        .rc-tooltip-inner{
            color: $FONT_COLOR;
        }
    }
}

.with-existing-btn {
    .rc-tooltip-inner
    {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    &.error{
        .rc-tooltip-inner{
            padding: 20px;
            max-width: 300px;
        }
    }
    .use-existing{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0;
        > label{
            width: 13px;
            height: 13px;
            border: 1px solid #888;
        }
        .mr-7{
            margin-right: 7px;
        }
        button {
            margin-left: 10px;
            text-transform: capitalize;
        }
    }
    .ta-right{
        text-align: right;
    }
    button {
        max-width: 120px;
        padding: 4px 14.33px !important;
        border: 1px solid $GREEN;
        text-transform: capitalize;
        &:hover {
            color: $BLACK_ERROR !important;
        }
    }
}
input.large[type="checkbox"]
{
    width: 15px;
    height: 15px;
}
input.large[type="checkbox"] + label
{
    width: 15px;
    height: 15px;
    margin-left: -15px;
}
body[data-modal="authentication"]
{
    min-width: auto;
    .ui-modal-box .modal {
        @media (max-width: 768px) {
            min-width: auto;
        }
    }
}
.disable-pointer-events{
    pointer-events: none;
}
.disable-remove-col{
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .ui-icon {
        opacity: 0.5;
    }
}

.revision-input
{
    width: $DEFAULT_REVISION_INPUT_WIDTH !important;
    height: $REVISION_INPUT_HEIGHT !important;
    text-align: left !important;
    &.custom-rev-scheme
    {
        width: $CUSTOM_REVISION_INPUT_WIDTH !important;
    }
}

.change-order-block
{
    white-space: nowrap;
    pointer-events: none;

    .text-block {
        height: auto !important;
    }
    .change-order-holder {
        display: flex;
        align-items: center;
        a {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            min-width: 82px;
            max-width: 90%;
            padding-right: 10px;
            pointer-events: auto;
        }
        .status-label {
            line-height: 18px;
            min-width: 73px;
            text-align: center;

        }
    }
}

.change-order-wrapper {
    display: flex;
    white-space: nowrap;
    margin-top: 16px;
    .heading {
        min-width: 160px;
        margin-bottom: 0;
        color: #888888;
        font-size: 14px;
        font-weight: bold;
    }
  .change-order-holder {
        .status-label {
            line-height: 19px;
            margin-top: -2px;
         }
    }
}

.rounded-checkbox
{
    .checked-label
    {
        border-radius: 100%;
        width: 15px;
        height: 15px;
    }
}

.truncate-name
{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 0;
    display: inline-block;
    width: 60%;
}

.edit-pi-title {
    font-size: 23px;
}

.floatLeft { float: left; }

.width100 { width: 100% !important; }

.d-flex { display: flex; }
